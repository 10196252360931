<template>
  <b-tab>
    <template #title>
      {{ $t('product-page.vacation-package-details') }}
    </template>
    <div class="product-body">
      <div v-html="infoPack.description"></div>
      <div class="card place-offer">
          <div class="card-header">{{ $t("product-page.offer-place") }}</div>
          <div class="card-body">
              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xxl-6">
                <facility v-for="(item, index) in listFacilities" :key="index" :data="item.name" />
              </div>
              <div class="button-area">
                  <a href="#" class="btn" :class="{'toUp': showAllFacilities}" @click="changeShowingFacilities">{{ stateShowingFacility }}</a>
              </div>
          </div>
      </div>
      <div class="card place-offer">
          <div class="card-header">{{ $t("product-page.for-your-information") }}</div>
          <div class="card-body">
            <ul v-html="htmlInformation" class="pr-0"></ul>
          </div>
      </div>
      <div class="card place-offer">
        <div class="card-header">{{infoPack.address}}</div>
        <div class="card-body">
          <div class="location">
            <Map :address="infoPack.address"/>
          </div>
        </div>
      </div>
  </div>
  </b-tab>
</template>

<script>
import { BTab } from 'bootstrap-vue';
import TemplateInfoVacationPackage from './TemplateInfoVacationPackage';

export default {
  name: 'InfoVacationpackageTheme1',
  mixins: [TemplateInfoVacationPackage],
  components: {
    BTab,
    Facility: () => import('@/components/productPage/atoms/facility/FacilityTheme1'),
    Map: () => import('@/components/productPage/atoms/GoogleMap'),
  },
  data() {
    return {
      showAllFacilities: false,
    };
  },
  computed: {
    listFacilities() {
      return this.showAllFacilities ? this.infoPack.facilities : this.infoPack.facilities.slice(0, 12);
    },
    stateShowingFacility() {
      return this.showAllFacilities ? this.$t('product-page.hide-some-facilities') : this.$t('product-page.show-all-facilities');
    },
    htmlInformation() {
      let information = this.infoPack.description
        ? this.infoPack.description
        : ''; // this.$t('product-page.product-detail-information');
      information = information.replaceAll('\n\n', '\n');
      return information;
    },
  },
  methods: {
    changeShowingFacilities() {
      this.showAllFacilities = !this.showAllFacilities;
    },
  },
};
</script>

<style scoped>
  .card.place-offer .card-header {
      background-color: transparent;
      color: #000000;
      font-size: 22px;
      font-weight: 500;
      padding: 12px 20px;
  }
  .product-details .product-body .button-area{
      padding-top: 15px;
  }
  .product-details .product-body .button-area .btn {
      color: #000000;
      border-radius: 0;
      border-color: #707070;
      font-size: 13px;
      padding: 7px 15px;
      position: relative;
      padding-right: 25px;
  }
  .product-details .product-body .button-area .btn::after{
      content: "\f358";
      position: absolute;
      top: 7px;
      right: 7px;
      color: #000000;
      font-size: 13px;
      font-family: FontAwesome;
  }
  .product-details .product-body .button-area .btn.toUp::after{
    scale: 1 -1;
  }
  .card.place-offer .card-body p {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 15px;
  }
  .card.place-offer{
      margin-bottom: 24px;
  }
  .card.place-offer .card-body .location{
      width: 100%;
      height: 542px;
  }
  .card.place-offer .card-body .location iframe{
      width: 100%;
      height: 100%;
  }
  .product-details .product-body .card{
    padding-right: 0px;
  }
  .product-details .product-body .card .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}
</style>
